.modal-content {
  /* background-image: url("../../public/svgs/Modal/Background pattern decorative.svg"); */
  /* background-position: -100px -100px; top left corner */
  /* padding: calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px)
    calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px); */
  /* background-repeat: no-repeat;
  background-image: url("../../public/svgs/Modal/Background pattern decorative mini.svg"); */

  padding: 24px;
  border-radius: calc(var(--radius-xl) * 1px);
  width: 544px;
  /* background: red !important; */
}
.confirming-modal-container .modal-content {
  /* background-image: url("../../public/svgs/Modal/Background pattern decorative.svg"); */
  /* background-position: -100px -100px; top left corner */
  /* padding: calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px)
    calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px); */
  /* background-repeat: no-repeat;
  background-image: url("../../public/svgs/Modal/Background pattern decorative mini.svg"); */
  border: unset;
  padding: 24px;
  border-radius: calc(var(--radius-xl) * 1px);
  width: 544px;
  background: #0c111d !important;
}

.modal-90w {
  /* width: 100% !important; */
  justify-content: center !important;
  /* width: 97% !important; */
  /* background: red; */
  max-width: unset !important;
}
.confirming-modal-x-button-close {
  justify-content: end;
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
}
.modal-image-logout-type {
  position: absolute;
  /* left: 75px;
  top: 75px;
  width: 50px;
  height: 50px; */
  left: 32px;
  top: 32px;
  width: 34px;
  height: 34px;
}

.modal-image-stop-type {
  position: absolute;
  /* left: 70px;
  top: 75px;
  width: 50px;
  height: 50px; */

  left: 31px;
  top: 31px;
  width: 34px;
  height: 34px;
}
.modal-image-reset-type {
  position: absolute;
  /* left: 70px;
  top: 75px;
  width: 50px;
  height: 50px; */
  left: 31px;
  top: 31px;
  width: 34px;
  height: 34px;
}
.modal-image-pinunpin-type {
  position: absolute;
  /* left: 70px;
  top: 75px;
  width: 50px;
  height: 50px; */
  left: 31px;
  top: 31px;
  width: 34px;
  height: 34px;
}
.modal-image-hrthreshold-type {
  position: absolute;
  /* left: 70px;
  top: 75px;
  width: 50px;
  height: 50px; */
  left: 31px;
  top: 34px;
  width: 34px;
  height: 34px;
}

.modal-top-main-group-container {
  display: grid;

  gap: calc(var(--spacing-12-48px) * 1px);
}
.modal-top-component-container {
  /* padding-left: 15px;
  display: grid;
  gap: calc(var(--spacing-10-40px) * 1px); */

  /* padding-left: 8px; */
  display: flex;
  gap: 16px;
}
.selected-subject-images {
  position: relative;
  display: flex;
  height: 40px; /* Adjust to image height */
  width: 100%; /* Fit to the container width */
  max-width: 200px; /* Adjust this to control total space taken by images */
}

.selected-subject-image {
  width: 40px; /* Adjust image size */
  height: 40px; /* Adjust image size */
  border-radius: 50%; /* Circular images */
  object-fit: cover;
  border: 2px solid white; /* Optional border for spacing between images */
  z-index: auto; /* Automatic stacking */
}

.confirming-modal-backdrop {
  height: 100%;
  width: 100%;
  z-index: 2000;
  background-color: #121315; /* Semi-transparent black background */
  opacity: 0.75 !important;
}
.confirming-modal-button-group {
  /* gap: 32px; */
  gap: calc(var(--spacing-lg) * 1px);
  justify-content: flex-end;
  display: flex;
  width: 100% !important;
}

.modal-main-confirmation-info-container {
  display: grid;
  /* gap: calc(var(--spacing-12-48px) * 1px);  */
  gap: calc(var(--spacing-4xl) * 1px);
}
.confirming-modal-header-text-group {
  display: grid;
  gap: calc(var(--spacing-xs) * 1px);
}
.confirming-modal-title {
  /* color: var(--colors-text-text-primary-900); */

  /* Text lg/Semibold */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-lg) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--line-height-text-lg) * 1px); /* 155.556% */
}
.confirming-modal-description {
  /* color: var(--colors-text-text-tertiary-600); */

  /* Text sm/Regular */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 400;
  line-height: calc(var(--line-height-text-sm) * 1px); /* 142.857% */
}
.confirming-modal-submit-button {
  cursor: pointer;
  display: flex;
  padding: 10px calc(var(--spacing-xl) * 1px);
  justify-content: center;
  align-items: center;
  gap: calc(var(--spacing-sm) * 1px);
  border-radius: calc(var(--radius-md) * 1px);
  background: var(
    --component-colors-components-buttons-primary-button-primary-bg
  );
  color: var(--component-colors-components-buttons-primary-button-primary-fg);
  height: min-content;
  /* Text md/Semibold */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--line-height-text-sm) * 1px); /* 150% */
}
.confirming-modal-cancel-button {
  cursor: pointer;
  display: flex;
  padding: 10px calc(var(--spacing-xl) * 1px);
  justify-content: center;
  align-items: center;
  gap: calc(var(--spacing-sm) * 1px);
  border-radius: calc(var(--radius-md) * 1px);
  /* border: 1px solid
    var(--component-colors-components-buttons-secondary-button-secondary-border); */
  /* background: var(
    --component-colors-components-buttons-secondary-button-secondary-bg
  ); */
  /* color: var(
    --component-colors-components-buttons-secondary-button-secondary-fg
  ); */

  /* Text md/Semibold */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--line-height-text-sm) * 1px); /* 150% */
}
.modal-circular-img {
  width: 48px;
  height: 48px;
}
.modal-circular-container {
  display: flex;
  width: 48px;
  min-width: min-content;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--radius-full) * 1px);
  background-color: var(--colors-foreground-fg-senary-300);
}
.modal-circular-container-blue {
  display: flex;
  width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--radius-full) * 1px);
  background-color: var(--component-colors-utility-brand-utility-brand-50);
}
.modal-circular-container-red {
  display: flex;
  width: 48px;
  min-width: min-content;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--radius-full) * 1px);
  background-color: var(--colors-background-bg-error-secondary);
}
.confirming-modal-subject-image {
  height: 46px;
  width: 46px;

  border-radius: calc(var(--radius-full) * 1px);
  /* margin-bottom: 12px; */
  object-fit: cover;
}
.confirming-modal-subject-image-placeholder {
  height: 46px;
  width: 46px;

  border-radius: calc(var(--radius-full) * 1px);

  padding: 5px;
  object-fit: cover;
  background-color: var(--colors-foreground-fg-senary-300);
  /* border: 1px solid var(--colors-border-border-secondary) */
}
.confirming-modal-subject-information-container {
  display: grid;
}
.confirming-modal-subject-name {
  /* color: var(--colors-text-text-primary-900); */

  /* Text lg/Semibold */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-lg) * 1px);
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--line-height-text-lg) * 1px); /* 155.556% */
}
.confirming-modal-subject-location-id {
  /* color: var(--colors-text-text-tertiary-600); */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-md) * 1px); /* 150% */
}

@media screen and (max-width: 390px) {
  .modal-content {
    padding: 20px 16px 16px 16px;
    border-radius: 23px;
    width: 343px;
  }
  .modal-top-component-container {
    display: grid !important;
    gap: calc(var(--spacing-xl) * 1px);
  }
  .modal-main-confirmation-info-container {
    gap: calc(var(--spacing-3xl) * 1px);
  }
  .confirming-modal-button-group {
    /* gap: 32px; */
    gap: calc(var(--spacing-lg) * 1px);
    display: grid;
    justify-content: unset;
    width: 100% !important;
  }
  .confirming-modal-cancel-button {
    order: 2;
    /* box-sizing: border-box; */
    width: 100% !important;
  }
  .confirming-modal-submit-button {
    order: 1;
    /* box-sizing: border-box; */
    width: 100% !important;
  }
}
