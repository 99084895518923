.detailed-wellness-graph-container {
  padding-left: calc(81px + var(--container-padding-desktop) * 1px);
  padding-right: calc(81px + var(--container-padding-desktop) * 1px);
  padding-top: 32px;
  padding-bottom: 48px;
  display: grid;
  gap: 32px;
}
.detailed-wellness-graph-header-title {
  /* color: var(--colors-text-text-primary-900, #101828); */
  font-family: var(--font-family-font-family-display);
  font-size: calc(var(--font-size-display-sm) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--line-height-display-sm) * 1px); /* 126.667% */
}
.detailed-wellness-graph-chart-container {
  display: grid;
  padding: 24px;
  gap: 32px;
  position: relative;
  border-radius: calc(var(--radius-xl) * 1px);
  /* border: 1px solid var(--colors-border-border-secondary); */
  box-shadow: 0px calc(var(--shadow-xs-offset-y) * 1px)
    calc(var(--shadow-xs-blur) * 1px) 0px
    var(--colors-effects-shadows-shadow-xs);
  width: 100%;
  cursor: grab;
}
.detailed-wellness-group-graph-chart-container {
  display: grid;
  gap: 32px;
}
.detailed-wellness-graph-chart-title {
  /* color: var(--colors-text-text-primary-900); */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-lg) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--line-height-text-lg) * 1px); /* 155.556% */
}
.detailed-wellness-graph-custom-tooltip-container {
  border-radius: calc(var(--radius-md) * 1px);
  /* background: var(--colors-background-bg-primary-solid); */
  padding: calc(var(--spacing-lg) * 1px);
  display: grid;
  gap: calc(var(--spacing-xs) * 1px);
  width: max-content;
}
.wellness-detailed-temp-dev-waiting-for-data {
  color: #969696;
  text-align: center;
  font-family: "League Spartan";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wellness-detailed-graph-movement-legend-container {
  display: flex;
  gap: 24px;
  justify-content: center; /* Center the legend container horizontally */
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}
.wellness-detailed-graph-movement-category-group {
  display: flex;
  gap: 4px;
  align-items: center;
}
.wellness-detailed-graph-movement-legend-text {
  /* color: var(--colors-text-text-disabled); */
  display: flex;
  align-items: center;
  text-align: center;
  /* Text xs/Regular */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-xs) * 1px);
  font-style: normal;
  font-weight: 400;
  line-height: calc(var(--line-height-text-xs) * 1px); /* 150% */
}
.wellness-detailed-graph-movement-legend-squares {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 2px;
}
.detailed-wellness-graph-refresh-download-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
}
.recharts-brush-traveller {
  /* display: none; */
  pointer-events: none;
}
.recharts-brush-texts {
  display: none; /* Hides the text inside the brush */
}
.wellness-detailed-graph-movement-legend-circles {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 100%;
}
.detailed-wellness-graph-custom-tooltip-label {
  /* color: var(--colors-text-text-white); */

  /* Text xs/Semibold */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-xs) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--line-height-text-xs) * 1px); /* 150% */
}
.detailed-wellness-graph-custom-tooltip-value {
  /* color: var(--component-colors-components-tooltips-tooltip-supporting-text); */

  /* Text xs/Medium */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-xs) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-xs) * 1px); /* 150% */
}
/* .detailed-wellness-graph-container .recharts-bar-rectangle:hover {
  fill: red;
}
.detailed-wellness-graph-container .recharts-layer {
  background: transparent;
} */
.detailed-wellness-graph-chart-container.fetching {
  position: relative;
  opacity: 0.5; /* Makes the content appear greyed out */
  pointer-events: none; /* Optional: prevent interactions while fetching */
}

.detailed-wellness-graph-chart-container .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none; /* Prevent interaction with the overlay */
}

.brush-hidden {
  display: none;
}
.brush-sticky-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Optional: Set background to match your design */
  padding: 10px 10px; /* Adjust the padding for visual spacing */
  z-index: 1000; /* Ensure it stays above other content */
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1000px) {
  .wellness-detailed-graph-movement-legend-container {
    top: 65px;
  }
}
@media screen and (max-width: 834px) {
  .detailed-wellness-graph-container {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media screen and (max-width: 415px) {
  .detailed-wellness-graph-refresh-download-container {
    gap: 8px;
  }
}

@media screen and (max-width: 390px) {
  .detailed-wellness-graph-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
