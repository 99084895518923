.device-notification-modal .modal-content {
  /* background-image: url("../../public/svgs/Modal/Background pattern decorative.svg"); */
  /* background-position: -100px -100px; top left corner */
  /* padding: calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px)
      calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px); */
  /* background-repeat: no-repeat;
    background-image: url("../../public/svgs/Modal/Background pattern decorative mini.svg"); */

  padding: 24px;
  border-radius: calc(var(--radius-xl) * 1px);
  width: 544px;
  background: #f2f4f7;
  border: unset;
  box-shadow: 0px 20px 24px -4px var(--colors-effects-shadows-shadow-xl-01),
    0px 8px 8px -4px var(--colors-effects-shadows-shadow-xl-02);
  /* background: red !important; */
}
.confirming-modal-container .modal-content {
  /* background-image: url("../../public/svgs/Modal/Background pattern decorative.svg"); */
  /* background-position: -100px -100px; top left corner */
  /* padding: calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px)
      calc(var(--spacing-12-48px) * 1px) calc(var(--spacing-8-32px) * 1px); */
  /* background-repeat: no-repeat;
    background-image: url("../../public/svgs/Modal/Background pattern decorative mini.svg"); */
  border: unset;
  padding: 24px;
  border-radius: calc(var(--radius-xl) * 1px);
  width: 544px;
  background: #0c111d !important;
}
.device-notification-confirming-modal-backdrop {
  height: 100%;
  width: 100%;
  z-index: 2000;
  background-color: #ffffffb2; /* Semi-transparent black background */
  opacity: 0.7 !important;
}
