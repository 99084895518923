/* .toaster-bg-danger {
  background-color: #f13322;

} */
.toast-container {
  position: fixed;
  z-index: 9999;
}
.critical-toast-card {
  width: 400px;
  max-width: 400px;
  border-radius: calc(var(--radius-2xl) * 1px);
  background: var(--colors-background-bg-primary);

  box-shadow: 0px 20px 24px -4px var(--colors-effects-shadows-shadow-xl_01),
    0px 8px 8px -4px var(--colors-effects-shadows-shadow-xl_02);
}
body.dark-theme .critical-toast-card {
  background: #0c111d;
}

.critical-toast-card-top-section {
  display: flex;
  padding: 21px calc(var(--spacing-3xl) * 1px) 0px 22px;
  justify-content: flex-start;
}

.critical-toast-information-jms-booking-id {
  color: var(--colors-text-text-tertiary-600);
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-md) * 1px); /* 150% */
}

body.dark-theme .critical-toast-information-jms-booking-id {
  color: #94969c;
}

.critical-toast-card-responding-button {
  display: flex;
  padding: calc(var(--spacing-md) * 1px) calc(var(--spacing-lg) * 1px);
  align-items: center;
  gap: 8px;
  border-radius: calc(var(--radius-md) * 1px);
  background: var(--colors-text-text-primary-900);
  color: var(--colors-text-text-white);
  cursor: pointer;
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-md) * 1px);
}

body.dark-theme .critical-toast-card-responding-button {
  background: #f5f5f6;
  color: var(--colors-text-text-tertiary-600);
}

.critical-toast-card-occupied-button {
  display: flex;
  padding: calc(var(--spacing-md) * 1px) calc(var(--spacing-lg) * 1px);
  align-items: center;
  gap: 8px;
  border-radius: calc(var(--radius-md) * 1px);
  background-color: var(--colors-background-bg-tertiary);
  color: var(--colors-text-text-tertiary-600);
  cursor: pointer;
  /* Text md/Medium */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-md) * 1px); /* 150% */
}
body.dark-theme .critical-toast-card-occupied-button {
  background-color: #1f242f;
  color: #94969c;
}

.critical-toast-card-bottom-section {
  display: flex;
  gap: 12px;
  padding: 20px 24px 24px 20px;
  width: 100% !important;
  justify-content: flex-end;
}

.critical-toast-card-profile-image {
  width: 46px;
  height: 46px;
  border-radius: calc(var(--radius-full) * 1px);
  object-fit: cover;
}
.critical-toast-card-priority-svg {
  width: 40px;
  height: 40px;
}

.critical-toast-information-location-container {
  display: flex;
  text-align: start;
  justify-content: flex-start;
  font-size: 11.88px;
  font-weight: 400;
  color: var(--colors-text-text-tertiary-600);
  gap: calc(var(--spacing-xs) * 1px);
}

.toast-subject-card-information-container {
  width: 100%;
}

.toast-subject-card-name {
  color: var(--colors-text-text-white);
  font-family: "DM Sans";
  font-size: calc(var(--Font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex-wrap: nowrap !important;
  width: 100%;
}
.toast-subject-card-id {
  color: var(--colors-text-text-white);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.toast-subject-card-status {
  display: inline-flex;
  padding: calc(var(--spacing-xs) * 1px) 10px;
  align-items: center;
  border-radius: calc(var(--radius-md) * 1px);
  border: 1px solid
    var(--component-colors-utility-gray-blue-utility-gray-blue-200);
  background: var(--component-colors-utility-gray-blue-utility-gray-blue-50);
  text-align: center;
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-sm) * 1px);
}
.toast-subject-card-location {
  color: var(--colors-foreground-fg-tertiary-hover);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  gap: 8px;
}

.critical-toast-information-section {
  padding: 14px calc(var(--spacing-3xl) * 1px) 0px
    calc(var(--spacing-3xl) * 1px);
  text-align: start;
}
.critical-toast-information-location-attribute {
  color: var(--colors-text-text-primary-900);
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 400;
  line-height: calc(var(--line-height-text-md) * 1px);
}
body.dark-theme .critical-toast-information-location-attribute {
  color: #f5f5f6;
}
.critical-toast-information-truncated-location {
  color: var(--colors-text-text-primary-900);
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--line-height-text-md) * 1px);
}
body.dark-theme .critical-toast-information-truncated-location {
  color: #f5f5f6;
}
.critical-toast-information-name {
  color: var(--colors-text-text-primary-900);
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-lg) * 1px);
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--line-height-text-lg) * 1px); /* 155.556% */
}

body.dark-theme .critical-toast-information-name {
  color: #f5f5f6;
}

.critical-toast-information-description {
  color: var(--colors-text-text-error-primary-600);
  text-align: start;
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-lg) * 1px);
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--line-height-text-lg) * 1px);
}

.critical-toast-information-description-location-container {
  padding-top: 8px;
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background: var(--colors-foreground-fg-error-secondary);
  /* background-color: rgba(0, 0, 0, 0.469);  */
  /* This blurs the overlay itself, giving the impression of blurring what's underneath */
  z-index: 9999; /* Ensure this is below the toast (which might have a higher z-index like 1050) */
}
body.dark-theme .blur-overlay {
  background: #f97066;
}

.status-change-toast-image {
  height: 30px;
  min-height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: calc(var(--radius-full) * 1px);
  object-fit: cover;
  margin-right: 8px;
}

.toast-subject-card-dashed-lines {
  width: 24px;
  height: 56px;
  position: absolute;
  bottom: 29px;
  left: 37px;
}

.toast-subject-card-dashed-lines-mini {
  width: 24px;
  height: 6px;
  position: absolute;
  bottom: 61px;
  left: 37px;
}

.status-change-container {
  width: 384px;
  min-height: 62px;
  border-radius: calc(var(--radius-md) * 1px);
  background: var(--colors-background-bg-secondary-solid);
  padding: 11px 7px 12px 8px;
  display: flex;
  align-items: center;
}

.status-change-svg {
  width: 55px;
  height: 55px;
  margin-top: 15px;
}
.toast-critical-realert-container {
  width: 72px;
  height: 72px;
  bottom: 20px;
  right: 20px;
  color: black !important;
  position: fixed;
  border: 4px solid #fee4e2;
  border-radius: calc(var(--radius-full) * 1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8000;
  cursor: pointer;
}
.toast-critical-realert-circle-container {
  background-color: var(--colors-background-bg-error-secondary);
  width: 56px;
  height: 56px;
  border-radius: calc(var(--radius-full) * 1px);
  color: #d92d20;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toast-critical-realert-image {
  width: 28px;
  height: 28px;
}
@media screen and (max-width: 809px) {
  /* .critical-toast-card {
    width: 343px;
  }
  .critical-toast-card-top-section {
    padding-top: 47px;
    padding-bottom: unset;
    gap: calc(var(--spacing-2xl) * 1px);
  }
  .critical-toast-card-profile-image {
    width: 92px;
    height: 92px;
  }
  .critical-toast-card-priority-svg {
    width: 64px;
    height: 64px;
  }
  .critical-toast-information-name {
    font-size: 18px;
  }
  .critical-toast-information-description {
    font-size: 14px;
  }
  .critical-toast-information-location-container {
    font-size: 14px;
  }
  .critical-toast-card-bottom-section {
    padding: calc(var(--spacing-3xl) * 1px) calc(var(--spacing-xl) * 1px)
      calc(var(--spacing-xl) * 1px) calc(var(--spacing-xl) * 1px);
  }
  .critical-toast-card-buttons {
    padding: 10px calc(var(--spacing-xl) * 1px) 10px
      calc(var(--spacing-xl) * 1px);
    font-size: 16px;
  }
  .toast-critical-realert-container {
    width: 72px;
    height: 72px;
    bottom: 64px;
    right: 16px;
    border: 2px solid #fee4e2;
  }
  .toast-critical-realert-circle-container {
    width: 56px;
    height: 56px;
  }
  .toast-critical-realert-image {
    width: 28px;
    height: 28px;
  } */
}
@media screen and (max-width: 430px) {
  .critical-toast-card {
    width: 342px;
    max-width: 342px;
  }
  .critical-toast-card-bottom-section {
    justify-content: flex-start;
  }
}
