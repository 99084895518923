/* @import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&family=Orbitron:wght@400;500;600;700;800&display=swap"); */
/* light */

@import "./variables.css";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import "./Components/userlistcard.css";
@import "./Components/Buttons/button.css";
@import "./Components/Search/search.css";
@import "./Components/userlist.css";
@import "./Components/WellnessGraphs/wellnessgraphs.css";
/* html{
  height: 100%;
  margin: 0 ;
  padding: 0;
  overflow: auto;
 

  justify-content: center;

} */
body {
  height: 100%;
  margin: 0 auto !important;
  padding: 0;
  overflow: auto;
  /* max-width: 1920px; */
  min-width: 390px;
  /* display:flex; */
  /* justify-content: center; */
}
body {
  background: var(--colors-background-bg-primary);
  background-size: 100% 100% !important;
  font-family: "DM Sans" !important;
  /* color:white !important */
}

.light-grey-shade {
  background: #cf1b1b !important;
}

/* .dark-Theme-text-color {
  color: white;
}

.dark-card-color {
  background-color: var(--colors-border-border-secondary) !important;
} */

body.dark-theme {
  background: #0c111d;
  background-size: 100% 100% !important;
  font-family: "DM Sans" !important;
}
