/* ------------------------------------------------------ */
.user-list-main-container {
  padding-left: calc(81px + var(--container-padding-desktop) * 1px);
  padding-right: calc(81px + var(--container-padding-desktop) * 1px);
  padding-top: 48px;
  padding-bottom: 48px;
  gap: 32px;
  display: grid;
  width: 100%;
}

.user-list-header-group {
  display: flex;
  gap: calc(var(--spacing-3xl) * 1px);
  align-items: center !important;
  /* padding-bottom: calc(40px + var(--spacing-2xl) * 1px); */
}
.user-list-features-filtering-group {
  display: flex;
  /* justify-content: flex-end; */
  /* gap: calc(var(--spacing-3xl) * 1px); */
  /* height: min-content !important; */
  width: 100%;
}

.user-list-title-description-group {
  display: grid;
  gap: calc(var(--spacing-xs) * 1px);
}

/* .user-list-search-component-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
} */
.user-list-buttons-group-container {
  display: flex;
  width: 100%;
  gap: calc(var(--spacing-lg) * 1px);
  align-items: center;
}

.user-list-buttons-group-container-admin {
  display: flex;
  width: 100%;
  gap: calc(var(--spacing-lg) * 1px);
  align-items: center;
}

.user-list-title {
  color: var(--colors-text-text-primary-900);
  font-family: var(--font-family-font-family-display);
  font-size: calc(var(--font-size-display-sm) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--line-height-display-sm) * 1px); /* 126.667% */
}

.user-list-description {
  color: var(--colors-text-text-tertiary-600);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.user-list-invite-user-button {
  display: flex;
  gap: calc(var(--spacing-sm) * 1px);
  padding: 10px calc(var(--spacing-xl) * 1px) 10px calc(var(--spacing-xl) * 1px);
  border-radius: calc(var(--radius-full) * 1px);
  white-space: nowrap;
  /* height: fit-content; */
  background-color: var(
    --component-colors-components-buttons-primary-button-primary-bg
  );
  color: var(--component-colors-components-buttons-primary-button-primary-fg);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.user-list-checkbox {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  display: center;
  white-space: nowrap;
}

.user-list-checkbox .form-check-label {
  margin-left: 6px; /* Adjust the spacing between the toggle and the label */
  display: flex;
  align-items: center;
  color: var(--colors-text-text-secondary-700);

  /* Text md/Medium */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-md) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-md) * 1px); /* 150% */
}

.user-list-checkbox .form-check-input {
  height: 24px;
  width: 44px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0; /* Reset any top margin */
  margin-bottom: 0; /* Reset any bottom margin */
  display: inline-block;
  vertical-align: middle;
}

.users-list-title-container {
  padding: 32px 24px;
  border-top: 1px solid var(--colors-border-border-secondary);
  border-left: 1px solid var(--colors-border-border-secondary);
  border-right: 1px solid var(--colors-border-border-secondary);
  border-top-left-radius: calc(var(--radius-xl) * 1px);
  border-top-right-radius: calc(var(--radius-xl) * 1px);
  color: var(--colors-text-text-primary-900);
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-lg) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--line-height-text-lg) * 1px); /* 155.556% */
}

body.dark-theme .users-list-title-container {
  border-top: 1px solid #1f242f !important;
  border-left: 1px solid #1f242f !important;
  border-right: 1px solid #1f242f !important;
}

.user-list-checkbox .form-check-input:focus {
  outline: none;
}

.user-list-checkbox .form-check-input:checked {
  background-color: var(--colors-background-bg-brand-solid);
  border-color: unset;
  border: unset;
}
.user-list-checkbox .form-check-input:not(:checked):focus {
  box-shadow: unset;
  /* border-color: unset; */
  border: 1px solid #b2b4b6;
}

.user-list-checkbox .form-check-input:checked:focus {
  box-shadow: unset;
}

.user-list-checkbox .form-check-input:hover {
  box-shadow: unset;
}

.user-list-checkbox .form-check-input:checked:hover {
  box-shadow: unset;
}

/* @media screen and (max-width: 960px) {
  .user-list-main-container {
    padding-left: calc(var(--spacing-md) * 1px);
    padding-right: calc(var(--spacing-md) * 1px);
  }
} */
.user-list-button-grouping {
  display: flex;
  gap: calc(var(--spacing-lg) * 1px);
}
.user-list-button-grouping-admin {
  display: flex;
  gap: calc(var(--spacing-lg) * 1px);
}
.user-list-table-header-cell {
  padding: calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px)
    calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px);
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--colors-background-bg-secondary);
  /* border: 1px solid var(--colors-border-border-secondary); */
  color: var(--colors-text-text-tertiary-600);
  width: 16% !important;
}
body.dark-theme .user-list-table-header-cell {
  background-color: #161b26 !important;
  color: #94969c !important;
}

.user-list-table-header-cell-delete {
  padding: calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px)
    calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px);
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--colors-background-bg-secondary);
  /* border: 1px solid var(--colors-border-border-secondary); */
  color: var(--colors-text-text-tertiary-600);
  width: 3% !important;
}
.user-list-table-header-cell-nfc-tools {
  padding: calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px)
    calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px);
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--colors-background-bg-secondary);
  /* border: 1px solid var(--colors-border-border-secondary); */
  color: var(--colors-text-text-tertiary-600);
  width: 7.5% !important;
}
.table-header-text {
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colors-text-text-tertiary-600);
}
.user-list-table-header-group {
  border: 1px solid var(--colors-border-border-secondary);
}
body.dark-theme .user-list-table-header-group {
  border: 1px solid #1f242f !important;
}

.user-list-table-header-group tr:first-child,
.user-list-table-header-group th:first-child {
  /* border-top-left-radius: calc(var(--radius-xl) * 1px); */
  border-left: 1px solid var(--colors-border-border-secondary);
  border-top: 1px solid var(--colors-border-border-secondary);
  border-bottom: 1px solid var(--colors-border-border-secondary);
  border-right: unset;
}

body.dark-theme .user-list-table-header-group tr:first-child,
body.dark-theme .user-list-table-header-group th:first-child {
  /* border-top-left-radius: calc(var(--radius-xl) * 1px); */
  border-left: 1px solid #1f242f !important;
  border-top: 1px solid #1f242f !important;
  border-bottom: 1px solid #1f242f !important;
  border-right: unset;
}

.user-list-table-header-group tr:first-child,
.user-list-table-header-group th:last-child {
  /* border-top-right-radius: calc(var(--radius-xl) * 1px) !important; */
  border-right: 1px solid var(--colors-border-border-secondary) !important;
  border-top: 1px solid var(--colors-border-border-secondary);
  border-bottom: 1px solid var(--colors-border-border-secondary);
  border-left: unset;
}

body.dark-theme .user-list-table-header-group tr:first-child,
body.dark-theme .user-list-table-header-group th:last-child {
  /* border-top-right-radius: calc(var(--radius-xl) * 1px) !important; */
  border-right: 1px solid #1f242f !important;
  border-top: 1px solid #1f242f !important;
  border-bottom: 1px solid #1f242f !important;
  border-left: unset;
}

.user-list-table-header-group th:nth-child(2),
.user-list-table-header-group th:nth-child(3),
.user-list-table-header-group th:nth-child(4),
.user-list-table-header-group th:nth-child(5) {
  border-right: unset;
  border-top: 1px solid var(--colors-border-border-secondary) !important;
  border-bottom: 1px solid var(--colors-border-border-secondary) !important;
  border-left: unset;
}

body.dark-theme .user-list-table-header-group th:nth-child(2),
body.dark-theme .user-list-table-header-group th:nth-child(3),
body.dark-theme .user-list-table-header-group th:nth-child(4),
body.dark-theme .user-list-table-header-group th:nth-child(5) {
  border-right: unset;
  border-top: 1px solid #1f242f !important;
  border-bottom: 1px solid #1f242f !important;
  border-left: unset;
}

.user-list-table-container {
  overflow: visible;
}
.user-list-table-container thead .user-list-table-header-cell-special {
  border-right: unset;
  border-top: 1px solid var(--colors-border-border-secondary);
  border-bottom: 1px solid var(--colors-border-border-secondary);
  border-left: unset;
}

body.dark-theme
  .user-list-table-container
  thead
  .user-list-table-header-cell-special {
  border-right: unset;
  border-top: 1px solid #1f242f !important;
  border-bottom: 1px solid #1f242f !important;
  border-left: unset;
}

.user-list-pagination .page-item {
  background-color: white;
  color: black;
  /* padding: calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px); */
}
.user-list-pagination {
  color: #0d6efd; /* Change to your desired text color */
  border-top: 1px solid var(--colors-border-border-secondary);
  padding: calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px)
    calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px);
}

body.dark-theme .user-list-pagination {
  color: #0d6efd; /* Change to your desired text color */
  border-top: 1px solid #1f242f !important;
  padding: calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px)
    calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px);
}

.user-list-pagination .page-link {
  color: var(--colors-text-text-secondary-hover);
  /* padding: calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px) !important; */

  font-size: 14px;
  font-weight: 600;
}
.user-list-pagination .page-item a {
  color: var(--colors-text-text-secondary-hover);
}
.user-list-pagination .page-item.active {
  background-color: white;
  color: var(--colors-text-text-secondary-hover);
  /* border: 1px solid var(--colors-border-border-secondary); */
}

.user-list-pagination .page-item.active a {
  color: var(--colors-text-text-secondary-hover);
}

.user-list-pagination .page-item:hover {
  background-color: #f0f0f0;
  color: var(--colors-text-text-secondary-hover);
  /* border: 1px solid var(--colors-border-border-secondary); */
}

.user-list-pagination .page-item.disabled {
  background-color: white;
  color: gray;
  /* border: 1px solid gray; */
}

.active > .page-link,
.page-link.active {
  background-color: var(
    --component-colors-components-buttons-primary-button-primary-bg
  );
  color: white;
  border: 1px solid
    var(--component-colors-components-buttons-primary-button-primary-bg);
}

.user-list-previous-next-group {
  display: flex;
  gap: calc(var(--spacing-md) * 1px);
}

@media screen and (max-width: 1400px) {
  .users-list-title-container {
    padding: 32px 24px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-bottom: 1px solid var(--colors-border-border-secondary);
  }

  body.dark-theme .users-list-title-container {
    padding: 32px 24px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-bottom: 1px solid #1f242f !important;
  }

  .user-list-main-container {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .user-list-table-header-group {
    display: none;
  }
}

@media screen and (max-width: 809px) {
  /* .user-list-header-group {
    display: grid;
    gap: calc(18px + var(--spacing-2xl) * 1px);
    padding-bottom: calc(40px + var(--spacing-2xl) * 1px);
  } */
  .user-list-features-filtering-group {
    display: flex;
    justify-content: unset;
    gap: calc(var(--spacing-3xl) * 1px);
    height: fit-content;
  }
  /* .user-list-main-container {
    padding-left: calc(var(--container-padding-desktop) * 1px);
    padding-right: calc(var(--container-padding-desktop) * 1px);
  } */
}
@media screen and (max-width: 770px) {
  .user-list-features-filtering-group {
    display: grid;
    width: 100%;
  }
  /* .user-list-search-component-container {
    display: flex;
    width: 100%;

    justify-content: center;
  } */
}
@media screen and (max-width: 575px) {
  .user-list-buttons-group-container-admin {
    display: grid;
    gap: 24px;
  }
}
@media screen and (max-width: 415px) {
  .user-list-buttons-group-container {
    display: grid;
    gap: 24px;
  }
}
@media screen and (max-width: 390px) {
  .user-list-main-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .user-list-button-grouping-admin {
    display: flex;
    justify-content: center;
  }
  .user-list-header-group {
    display: none;
  }
}
