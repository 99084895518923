.button-container {
  display: inline-block;
  align-items: center !important;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: calc(var(--radius-md) * 1px);
  gap: calc(var(--spacing-xs) * 1px);
  /* border: 1px solid
    var(--component-colors-components-buttons-secondary-button-secondary-border) !important; */
  box-shadow: 0px 1px 2px 0px var(--colors-effects-shadows-shadow-xs);
}
.button-image-container {
  display: inline-block;
  padding: 10px 14px 10px 14px;
  background: white;
  /* border: 1px solid
    var(--component-colors-components-buttons-secondary-button-secondary-border) !important; */
  border-radius: calc(var(--radius-md) * 1px);
  gap: calc(var(--spacing-xs) * 1px);
  box-shadow: 0px 1px 2px 0px var(--colors-effects-shadows-shadow-xs);
  width: min-content;
}
.button-image-container-dark {
  background: #161b26;
}

.buttons-text-sizing {
  padding-top: 0.75px !important;
  /* color: var(--component-colors-components-buttons-secondary-button-secondary-fg); */

  /* Text sm/Semibold */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--Line-height-text-sm) * 1px); /* 142.857% */
  white-space: nowrap !important;
  /* color: #344054; */
}
.buttons-text-sizing-dark {
  padding-top: 0.75px !important;
  /* color: var(--component-colors-components-buttons-secondary-button-secondary-fg); */

  /* Text sm/Semibold */
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--Line-height-text-sm) * 1px); /* 142.857% */
  white-space: nowrap !important;
  /* color: #cecfd2; */
}

.button-light {
  border: 1px solid
    var(--component-colors-components-buttons-secondary-button-secondary-border);
}

.button-light:hover {
  background-color: var(
    --component-colors-components-buttons-primary-button-primary-bg
  );
  border: 1px solid transparent !important;
  color: var(
    --component-colors-components-buttons-primary-button-primary-fg
  ) !important;
  cursor: pointer;
}

.button-light-isSelected {
  background-color: var(
    --component-colors-components-buttons-primary-button-primary-bg
  );
  border: 1px solid transparent !important;
  color: var(
    --component-colors-components-buttons-primary-button-primary-fg
  ) !important;
}
.button-disabled {
  border: 1px solid var(--colors-border-border-disabled-subtle) !important;
  background-color: var(--colors-background-bg-primary) !important;
  color: var(--colors-foreground-fg-disabled) !important;
  pointer-events: none;
}
body.dark-theme .button-disabled {
  border: 1px solid #1f242f !important;
  background-color: #161b26 !important;

  color: #cecfd2 !important;
  opacity: 0.5;
}
.button-disabled:hover {
  border: 1px solid var(--colors-border-border-disabled-subtle) !important;
  background-color: var(--colors-background-bg-primary) !important;
  color: var(--colors-foreground-fg-disabled) !important;
  pointer-events: none;
}

/* @media screen and (max-width: 809px) {
  .button-image-container {
    padding: calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px)
      calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px) !important;
  }
  .button-display-text {
    display: none;
  }
} */
