.main-search-component {
  padding: 10px 14px 10px 40px; /* Adjust the left padding to accommodate the icon */
  border-radius: calc(var(--radius-md) * 1px);
  /* border: 1px solid red; */
  width: calc(
    var(--width-xxs) * 1px
  ); /* Ensure the input field takes up the entire width */
  line-height: 0px !important;
}
.main-search-component:focus {
  box-shadow: 0px 0px 0px 4px #0f2f413d, 0px 1px 2px 0px #1018280d !important;
  /* border: 1px solid var(--colors-border-border-brand) !important; */
  outline: unset;
}

.main-search-component-text-dark::placeholder {
  color: #94969c !important;
}
.search-container {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  outline: 0px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 14px; /* Adjust the left position for better alignment */
  transform: translateY(-50%);

  color: #ccc;
}

.main-search-component-user-list {
  padding: 10px 14px 10px 40px; /* Adjust the left padding to accommodate the icon */
  border-radius: calc(var(--radius-md) * 1px);
  /* border: 1px solid var(--colors-border-border-primary); */
  width: calc(
    var(--width-xxs) * 1px
  ); /* Ensure the input field takes up the entire width */
  line-height: 0px !important;
}
.main-search-component-user-list-admin {
  padding: 10px 14px 10px 40px; /* Adjust the left padding to accommodate the icon */
  border-radius: calc(var(--radius-md) * 1px);
  /* border: 1px solid var(--colors-border-border-primary); */
  width: calc(
    var(--width-xxs) * 1px
  ); /* Ensure the input field takes up the entire width */
  line-height: 0px !important;
}

@media screen and (max-width: 1400px) {
  .main-search-component-user-list-admin {
    width: 171px;
  }
}

@media screen and (max-width: 770px) {
  .search-container {
    width: 100% !important;
  }
  .main-search-component-user-list {
    display: flex;
    width: 100% !important;
  }
  .main-search-component-user-list-admin {
    /* display: flex; */
    width: 100% !important;
  }
}
@media screen and (max-width: 442px) {
  .main-search-component {
    width: 100%;
  }
}
